import { useEffect, useMemo, useState } from 'react';
import styles from './home.module.scss';
import cn from 'classnames';
import { FarmingStates } from '../../../types';
import { useHomeScreen } from './home.hooks';
import { getTelegramPhotoUrl } from '../../../api/common';
import { DailyModal } from './components';

export default function Home() {
  const { 
    farmingState, 
    user, 
    handleStartFarming,
    handleClaimFarming,
    jobAmount,
    currentAmount,
    timeToFinish,
    progressPercent,
    accessToken,
    dailyRewardInfo,
    handleClaimDailyReward
  } = useHomeScreen();

  const parsedTimeToFinish = useMemo(() => {
    if (timeToFinish < 0) {
      return { hours: '00', minutes: '00' }
    }
    const hours = Math.floor(timeToFinish / 3600)
    return { 
      hours: hours.toString().padStart(2, '0'),
      minutes: (Math.floor((timeToFinish - hours * 3600) / 60)).toString().padStart(2, '0')
    }    
  }, [timeToFinish])

  const [photoUrl, setPhotoUrl] = useState('')
  
  useEffect(() => {
    const newPhotoUrl = getTelegramPhotoUrl()
    if (!newPhotoUrl || newPhotoUrl === photoUrl) {
      return
    }
    setPhotoUrl(newPhotoUrl)
    
  }, [])  

  return (
    <div className={styles.wrapper}>
      {dailyRewardInfo?.can_claim && <DailyModal dailyRewardInfo={dailyRewardInfo} handleClaimDailyReward={handleClaimDailyReward} />}
    <div className={cn(styles.circleWrap, {[styles.circleWrapActive]: farmingState === FarmingStates.FARMING})} />
      <div className={styles.cardsWrap}>
        <div className={cn(styles.profile)}>
          <div className={styles.profileInner}>
            <div className={styles.avatar}>
              {photoUrl && <img src={photoUrl} alt="avatar" />}
            </div>
            <div className={styles.profileInfo}>
            <span className={styles.profileName}>
              {user?.username || 'username'}
            </span>
            <div className={styles.balance}>
              <img src='/logo.svg' alt='logo' className={styles.bitcoinIcon} />
              <span>{Number(user?.balance || 0).toFixed(3)}</span>
            </div>
            </div>
            
          </div>
        </div>

        <div className={styles.serverWrap}>
          <div className={cn(styles.serverInner, {[styles.serverInnerActive]: farmingState === FarmingStates.FARMING})}>
            <img className={cn(styles.server, {[styles.serverActve]: farmingState === FarmingStates.FARMING})} alt="" src="/server.png"  />
            <img src='/logo.svg' alt='' className={cn(styles.serverLogo, {[styles.serverLogoActive]: farmingState === FarmingStates.FARMING})} />
          </div>
        </div>
      </div>

       <a
         className={styles.appLink}
         href='https://t.me/CyberbasePay_bot'
         target='_blank'
         rel='noreferrer'
       >
         CyberPay Card
       </a>

      {farmingState === FarmingStates.START && <button className={styles.farmingButton} onClick={handleStartFarming}>Start farming</button>}
      {farmingState === FarmingStates.FARMING && <div className={styles.farming} style={{ background: `linear-gradient(to right, #5c2374 0%, #5c2374 ${progressPercent}%, #B946E7 ${progressPercent}%, #B946E7 100%)` }}>
          <span>Farming</span>
          <span className={styles.farmingBalance}>
            <img src='/logo.svg' alt='logo' className={styles.bitcoinIconSmall} />
            <span className={styles.farmingBalanceValue}>{Number(currentAmount).toFixed(5)}</span>
          </span>
          <span className={styles.time}>{parsedTimeToFinish.hours}h {parsedTimeToFinish.minutes}m</span>
      </div>}
      {farmingState === FarmingStates.CLAIM && <button className={cn(styles.farming, styles.farmingClaim)} onClick={handleClaimFarming}>
        Claim
        <span className={styles.farmingBalance}>
          <img src='/logo.svg' alt='logo' className={styles.bitcoinIconSmall} />
          <span className={styles.farmingBalanceValue}>{Number(jobAmount).toFixed(5)}</span>
        </span>
      </button>}
    </div>
  );
}